export const inlineImages = `
_type == 'inlineImages' || _type == 'blockContentInlineImages' => {
  ...,
  images[] {
    ...,
    'image': image.asset->url + '?auto=format',
    'alt': image.alt
  }
}
`
