import { cta } from './cta'
import { imageObj } from './imageObj'
import { inlineImages } from './inlineImages'

export const blockContent = `
...,
${cta},
${inlineImages},
${imageObj},
markDefs[]{
  ...,
  _type == "internalLink" => {
    "slug": select(
      @.reference->slug.current == '/' => @.reference->slug.current,
      @.reference->slug.current != '/' => '/'+@.reference->slug.current,
    ),
  }
},`
